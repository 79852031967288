////////////////////////////////Constants///////////////////////////////////////////////////

export const loggingUser = "/login";
export const forgetPasswordEmail = "/forgot-password";
export const forgetPasswordOtp = "/verify-otp";
export const resetForgetPassword = "/reset-password";
export const changingPassword = "/change-password";
export const addingUser = "/user";
export const gettingRecentUsers = "/get_recent_users";
export const gettingDashboardDetail = "/analytics";

/////////////////////////////////Functions///////////////////////////////////////////////////

export const gettingAllUsers = (
  page = 1,
  search = null,
  limit = 10,
  filterUsers
) => {
  if (search !== null) {
    return `/users?page=${
      page * 1
    }&limit=${limit}&search=${search}&filterUsers=${filterUsers}`;
  } else {
    return `/users?page=${page * 1}&limit=${limit}&filterUsers=${filterUsers}`;
  }
};

export const gettingUser = (data) => {
  return `/user/${data}`;
};

export const updatingUser = (id) => {
  return `/user/${id}`;
};

export const deletingUser = (id) => {
  return `/user/${id}`;
};

export const changingStatus = (id) => {
  return `/change-status/${id}`;
};

export const gettingAnimalsData = (
  page = 1,
  search = null,
  type = "",
  limit = 10
) => {
  if (search !== null) {
    return `/animals/?page=${
      page * 1
    }&limit=${limit}&search=${search}&type=${type}`;
  } else {
    return `/animals/?page=${page * 1}&limit=${limit}&type=${type}`;
  }
};

export const gettingAnimal = (data) => {
  return `/animal/${data}`;
};

export const deletingAnimal = (id) => {
  return `/animal/${id}`;
};

import axios from "axios";
import * as constants from "./constants/appConstants";
import * as url from "./constants/urlConstants";

const instance = axios.create({
  // baseURL: "http://5fc1f26f427d.ngrok.io/QuotaGames/api/auth",
  baseURL: "https://bovinae.dmlabs.in/v1/admin",
  // headers: {
  //   // Authorization: constants.token ? `Bearer ${constants.token}` : null,
  //   Authorization: constants.token
  //     ? `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6NSwicm9sZSI6ImN1c3RvbWVyIiwiaWF0IjoxNjI3MzcxODE1fQ.3GX2--C5UfuRO2auz255M4taYsycfPUXyHhvWdpHQp8`
  //     : null,
  //   "Content-Type": "application/json",
  //   accept: "application/json",
  //   "Access-Control-Allow-Origin": "*",
  // },
});

const requestHandler = async (config) =>  {
    config.headers["Authorization"] = await localStorage.getItem("bovinae-access-token");
    return config;
  }


const forgetHandlerOne = (request) => {
  request.headers["Authorization"] = `${localStorage.getItem(
    "bovinae-forgot-token-1"
  )}`;
  return request;
};

const forgetHandlerTwo = (request) => {
  request.headers["Authorization"] = `${localStorage.getItem(
    "bovinae-forgot-token-2"
  )}`;
  return request;
};

instance.interceptors.request.use((request) => {
  if (request.url === url.forgetPasswordOtp) {
    forgetHandlerOne(request);
  } else if (request.url === url.resetForgetPassword) {
    forgetHandlerTwo(request);
  } else {
    requestHandler(request);
  }
  return request;
}, (error) => {
  return Promise.reject(error);
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (err) => {
    const originalRequest = err.config;
    let refreshToken = localStorage.getItem("refreshToken");
    if (err && err?.response?.data?.code === 400) {
      return err.response.data;
    } else if (
      refreshToken &&
      (err.response.status === 401 || err.response.status === 403)
    ) {
      let res = await instance.post(`/auth/refresh-token`, {
        refreshToken: refreshToken,
      });
      if (res.status && res.status === 200) {
        unAutherized(res.data.data);
        originalRequest.headers = {
          "Authorization": res.data.data.accessToken,
        };
        return instance(originalRequest);
      } else  {
        loggingOutUser();
      }
    } else {
      return err && err.response && err.response.data
        ? err.response.data
        : { status: 500 };
    }
  }
);

const unAutherized = (data) => {
  localStorage.setItem("bovinae-access-token", "Hello")
  localStorage.setItem("bovinae-refresh-token", "Hi");
};

const loggingOutUser = () => {
  if (localStorage.getItem("bovinae-access-token")) {
    localStorage.removeItem("bovinae-access-token");
    localStorage.removeItem("bovinae-refresh-token");
    window.location.href = "/login";
  }
};  

export default instance;
